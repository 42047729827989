<template>
  <img
    :src="require('@/assets/Bernese_Logo_1.svg')"
    width="128"
    height="128"
    alt="Bernese"
  >
</template>

<script>
  export default ({
    name: 'BerneseLogo',
  })
</script>

<style scoped>

</style>
