// Vuetify Documentation https://vuetifyjs.com

import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

import BerneseLogo from '@/components/base/BerneseLogo'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#42A5F6',
        secondary: '#000000',
        // secondary: '#050B1F',
        accent: '#204165',
      },
      dark: {
        primary: '#50778D',
        secondary: '#0B1C3D',
        accent: '#204165',
      },
    },
  },
  icons: {
    values: {
      bernese: {
        component: BerneseLogo,
      },
    },
  },
})
