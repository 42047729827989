import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    drawer: false,
    sections: [
      { text: 'Home', href: '#!' },
      { text: 'Services', href: '#services' },
      { text: 'Products', href: '#features' },
      { text: 'Partners', href: '#affiliates' },
      { text: 'Resources', href: '#resources' },
      { text: 'Contact', href: '#contact' },
    ],
  },
  getters: {
    sections: (state) => {
      return state.sections
    },
  },
  mutations: {
    setDrawer: (state, payload) => (state.drawer = payload),
    toggleDrawer: state => (state.drawer = !state.drawer),
  },
  actions: {

  },
})
